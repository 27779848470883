import React, { useEffect, useRef, useState } from "react";
// import { w3cwebsocket as WebSocket } from "websocket";
// import Animation from "./Animation";
import styled from "styled-components";
import axios from "axios";

// import liveLogo from "./assests/liveLogo.svg";
import liveLogo from "./assests/live/dpparty.jpeg";
// import walletImg from "./assests/wallet.png";
import liveVideo from "./assests/liveVideo.mp4";
// import liveVideo from "./assests/LiveVideo.mp4";
import ReEnterURL from "./Modals/ReEnterURL";
// import { useParams } from "react-router-dom";
// import { motion } from "framer-motion";
import Ranking from "./Modals/Ranking";
import GuestList from "./Modals/GuestList";
import ThankYouModal from "./Modals/ThankYouModal";
import useWebSocket from "react-use-websocket";

// gif
import money1000 from "./assests/live/moneyGif/money1000.gif";
import money500 from "./assests/live/moneyGif/money500.gif";
import money200 from "./assests/live/moneyGif/money200.gif";
import money100 from "./assests/live/moneyGif/money100.gif";


import { useParams, useNavigate } from 'react-router-dom';

const userDetails = JSON?.parse(sessionStorage?.getItem("hostD"));

function LiveVideo() {
  const [animate1000, setAnimate1000] = useState(false);
  const [animate500, setAnimate500] = useState(false);
  const [animate200, setAnimate200] = useState(false);
  const [animate100, setAnimate100] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [leaderBoardLoading, setLeaderBoardLoading] = useState(false);
  // const [leaderBoard, setLeaderBoard] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [getName, setGetName] = useState("");
  const [getCode, setGetCode] = useState("");
  const [hostName, setHostName] = useState("");
  const [imgHost, setImgHost] = useState("");
  const [jitsiLoading, setJitsiLoading] = useState(true);

  // const session = useRef(null);

  const headers = {
    "Content-Type": "application/json",
  };

  const navigate = useNavigate();

  const removeCodeFromURL = () => {
    navigate('/');
  };

  const { code } = useParams();

  const codeID = useRef(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      // const urlParts = window.location.href.split("/");
      
      codeID.current = code;
      
      const requestData = {
        code: codeID.current || (userDetails ? userDetails.code : null),
        secret: "999999999999999999999",
      };

      if (!requestData.code) {
        setLoading(false);
        return;
      }

      const response = await axios.post(
        "https://backend.partytime.ng/guestapi/getpartyinfo/",
        requestData,
        { headers }
      );

      if (response.data.status) {
        setGetCode(response.data.data?.event?.code);
        setGetName(response.data.data?.event?.unique_meeting_name);
        setHostName(response.data.data?.event?.hostname);
        setImgHost(response.data.data?.event?.hostimage);

        sessionStorage?.setItem(
          "hostD",
          JSON?.stringify(response.data.data?.event)
        );
        setShowModal(false);
        setLoading(false);
        removeCodeFromURL()
      }
    } catch (error) {
      setLoading(false);

      if (!userDetails) {
        setShowModal(true);
      }
    }
  };

  useEffect(() => {
    fetchData();

    // window.history.replaceState({}, document.title, "/");
  }, []); // Add userDetails to the dependency array

  // useEffect(() => {
  //   // Function to handle incoming messages
  //   const handleIncomingMessage = (message) => {
  //     console.log(message, typeof message);
  //     if (
  //       message == 1000 ||
  //       message == 100000 ||
  //       message?.message == 1000 ||
  //       message?.message == 100000
  //     ) {
  //       setAnimate1000(true);
  //     }
  //     if (
  //       message === "500" ||
  //       message?.message === "500" ||
  //       message?.message === "50000"
  //     ) {
  //       setAnimate500(true);
  //     }
  //     if (
  //       message === "200" ||
  //       message?.message === "200" ||
  //       message?.message === "20000"
  //     ) {
  //       setAnimate200(true);
  //     }
  //     if (
  //       message === "100" ||
  //       message?.message === "100" ||
  //       message?.message === "10000"
  //     ) {
  //       setAnimate100(true);
  //     }
  //   };

  //   // Function to create a WebSocket connection
  //   const createWebSocketConnection = () => {
  //     const socket = new WebSocket(
  //       `wss://backend.partytime.ng/ws/spray/${getCode}/`
  //     );

  //     socket.isHost = true;

  //     // WebSocket onopen event
  //     socket.onopen = (event) => {
  //       console.log("WebSocket connected");
  //     };

  //     // WebSocket onmessage event
  //     socket.onmessage = (event) => {
  //       const message = JSON.parse(event.data).message;
  //       handleIncomingMessage(message);
  //     };

  //     // WebSocket onclose event
  //     socket.onclose = (event) => {
  //       console.log("WebSocket connection closed:", event.code, event.reason);
  //       // Reconnect immediately if disconnected
  //       createWebSocketConnection();
  //     };

  //     // Clean up the WebSocket connection when the component unmounts
  //     return () => {
  //       socket.close();
  //     };
  //   };

  //   // Create a WebSocket connection if getCode is available
  //   if (getCode) {
  //     createWebSocketConnection();
  //   }
  // }, [getCode]);

  const socketUrl = `wss://backend.partytime.ng/ws/spray/${getCode}/`;

  const {
    // sendMessage,
    // sendJsonMessage,
    // lastMessage,
    lastJsonMessage,
    // readyState,
    // getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => console.log("opened"),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (lastJsonMessage !== null) {
      // setMessageHistory((prev) => prev.concat(lastJsonMessage));
      console.log(lastJsonMessage);
            if (
              lastJsonMessage == 1000 ||
              lastJsonMessage == 100000 ||
              lastJsonMessage?.message == 1000 ||
              lastJsonMessage?.message == 100000
            ) {
              setAnimate1000(true);
            }
            if (
              lastJsonMessage == 500 ||
              lastJsonMessage == 50000 ||
              lastJsonMessage?.message == 500 ||
              lastJsonMessage?.message == 50000
            ) {
              setAnimate500(true);
            }
            if (
              lastJsonMessage == 200 ||
              lastJsonMessage == 20000 ||
              lastJsonMessage?.message == 200 ||
              lastJsonMessage?.message == 20000
            ) {
              setAnimate200(true);
            }
            if (
              lastJsonMessage == 100 ||
              lastJsonMessage == 10000 ||
              lastJsonMessage?.message == 100 ||
              lastJsonMessage?.message == 10000
            ) {
              setAnimate100(true);
            }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    const initializeJitsi = () => {
      if (initialized) {
        // setIsLoading(true)
        return;
      }

      const domain = "media.partytime.ng";

      const options = {
        roomName: getName || userDetails?.getName,
        width: "100%",
        height: "100%",
        parentNode: document.querySelector("#meet"),
        lang: "en",
        configOverwrite: {
          startWithAudioMuted: false,
          startWithVideoMuted: false,
          prejoinPageEnabled: false,
          enableLobbyChat: true,
          disableInitialGUM: true,
          disableDeepLinking: true,
          preferH264: true,
        },
        userInfo: {
          displayName: hostName || userDetails?.hostName,
          avatar: imgHost || userDetails?.imgHost, // Replace with the URL of your avatar image
        },
        interfaceConfigOverwrite: {
          SHOW_CHROME_EXTENSION_BANNER: false,
          TOOLBAR_ALWAYS_VISIBLE: false,
          SETTINGS_SECTIONS: ["devices", "language"],
          SHOW_POWERED_BY: false,
          SHOW_BRAND_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          HIDDEN_DEEP_LINKING_LOGO: true,
          DEFAULT_LOGO_URL: "",
          DEFAULT_WELCOME_PAGE_LOGO_URL: "",
          JITSI_WATERMARK_LINK: "",
          TOOLBAR_BUTTONS: [
            "camera",
            "microphone",
            // "raisehand",
            // "desktop",
            "fullscreen",
            "videobackgroundblur",
            // "tileview",
            // "profile",
            "chat",
          ],
        },
      };

      const api = new window.JitsiMeetExternalAPI(domain, options);
      api.on("videoConferenceJoined", () => {
        // Set jitsiLoading to false when the conference is joined
        setJitsiLoading(false);
        setIsLoading(false); // Set isLoading to false when the conference is joined
        // console.log("finished", isLoading);
      });
      setIsLoading(true);
      // Remove the console.log statement related to isLoading
      // console.log("still loading", isLoading);
      setInitialized(true);
    };

    if (!initialized && getName) {
      const script = document.createElement("script");
      script.src = "https://media.partytime.ng/external_api.js";
      script.async = true;
      script.onload = initializeJitsi;

      const meetElement = document.querySelector("#meet");
      if (!meetElement) {
        return;
      }

      meetElement.appendChild(script);

      return () => {
        meetElement.removeChild(script);
      };
    }
  }, [initialized, getName, isLoading]);

  useEffect(() => {
    if (animate1000) {
      // Set a timeout to hide the GIF after it has finished playing
      const timer = setTimeout(() => {
        setAnimate1000(false);
      }, 3000); // Adjust the timeout value to match your GIF's duration

      return () => clearTimeout(timer);
    }

    if (animate100) {
      // Set a timeout to hide the GIF after it has finished playing
      const timer = setTimeout(() => {
        setAnimate100(false);
      }, 3000); // Adjust the timeout value to match your GIF's duration

      return () => clearTimeout(timer);
    }

    if (animate200) {
      // Set a timeout to hide the GIF after it has finished playing
      const timer = setTimeout(() => {
        setAnimate200(false);
      }, 3000); // Adjust the timeout value to match your GIF's duration

      return () => clearTimeout(timer);
    }

    if (animate500) {
      // Set a timeout to hide the GIF after it has finished playing
      const timer = setTimeout(() => {
        setAnimate500(false);
      }, 3000); // Adjust the timeout value to match your GIF's duration

      return () => clearTimeout(timer);
    }
  }, [animate1000, animate100, animate200, animate500]);

  return (
    <LiveVideoContainer>
      {isLoading && (
        <div className={`loading-animation ${isLoading ? "show" : ""}`}>
          <video width="640" height="360" autoPlay loop muted>
            <source src={liveVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      <>
        {/* id="meet" */}
        <JitsiContainer id="meet"></JitsiContainer>
        {/*  */}

        <AnimationContainer>
          {animate1000 && (
            <div className="animatedDiv">
              <div className="animatedMoney">
                <img src={money1000} alt="" width="100%" height="100%" />
              </div>
            </div>
          )}
          {animate500 && (
            <div className="animatedDiv">
              <div className="animatedMoney">
                <img src={money500} alt="" width="100%" height="100%" />
              </div>
            </div>
          )}
          {animate200 && (
            <div className="animatedDiv">
              <div className="animatedMoney">
                <img src={money200} alt="" width="100%" height="100%" />
              </div>
            </div>
          )}
          {animate100 && (
            <div className="animatedDiv">
              <div className="animatedMoney">
                <img src={money100} alt="" width="100%" height="100%" />
              </div>
            </div>
          )}
        </AnimationContainer>

        <Profile>
          <div className="imgDiv">
            {" "}
            <img src={imgHost || liveLogo} alt="" width="100%" height="100%" />
          </div>
          <div
            className="textFunct"
            style={{
              fontWeight: "800",
              fontSize: "1rem",
              color: "white",
              marginTop: "1rem",
            }}
          >
            Host
          </div>
          {/* <div className="walletImgDiv">
            {" "}
            <img src={walletImg} alt="" width="100%" height="100%" />
          </div> */}
        </Profile>

        <Functional>
          <aside>
            <ThankYouModal />
            <div className="textFunct">Exit</div>
          </aside>
          <aside>
            <Ranking />
            <div className="textFunct">Leaderboard</div>
          </aside>
          <aside>
            <GuestList />
            <div className="textFunct">Guests</div>
          </aside>
        </Functional>
      </>
      {showModal && <ReEnterURL setShowModal={setShowModal} />}
    </LiveVideoContainer>
  );
}

export default LiveVideo;

const LiveVideoContainer = styled.div`
  background-image: url(${userDetails && userDetails?.image});
  margin: 0;
  padding: 1rem; /* Adjust padding as needed */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #343434;
  position: fixed;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
  box-sizing: border-box;

  .loading-animation {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(5px); /* make it blur */

    background: #fff; /* Adjust background color as needed */
    z-index: 3000; /* Adjust the z-index to be above other content */
  }

  .typeani {
    /* font-family: pestaFont; */
  }

  .loading-animation.show {
    opacity: 0.8;
    z-index: 9;
  }
`;

const JitsiContainer = styled.div`
  width: 100%;
  height: 70vh !important;
  z-index: 100;
`;

const AnimationContainer = styled.div`
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  overflow: hidden;

  .animatedDiv {
    height: 85vh !important;
    overflow: hidden;
  }

  .animatedMoney {
    width: 300px;
    height: 100%;
  }

  @media screen and (max-width: 600px) {
    .animatedMoney {
      width: 170px;
      height: 100%;
    }
  }
`;

const Profile = styled.div`
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  /* z-index: 10; */
  /* background-color: rgba(245, 222, 179, 0.6); */

  /* border-radius: 0.4rem; */

  display: flex;
  flex-direction: column;
  align-items: center;

  .imgDiv {
    width: 100px;
    height: 100px;
    margin: auto;
    background-color: rgba(256, 256, 256, 0.6);
    border-radius: 50%;
    padding: 0.7rem;

    img {
      border-radius: 50%;
      width: 100%;
    }
  }
`;

const Functional = styled.div`
  position: absolute;
  bottom: 2%;
  z-index: 10;
  right: 2%;

  border-radius: 0.4rem;
  z-index: 1000;
  padding-top: 0.5rem;

  aside {
    /* background-color: rgba(256, 256, 256, 0.5); */
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-size: 0.8rem;
    gap: 5px;
    font-weight: 800;
  }

  .textFunct {
  }
`;
