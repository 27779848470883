import styled from "styled-components";
import { Colors } from "../Style/Colours";

export const BodyTemp = styled.main`
  background: ${Colors.BodyBgColor};
  padding: 1rem;
  min-height: 90vh;
`;

export const StyledModalContent = styled.div`
  padding: ${(props) => props.padding || "1rem"};
  border-radius: ${(props) => props.radius || ".4rem"};
  width: ${(props) => props.width};
  min-width: ${(props) => props.maxwidth};
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8);
  max-height: ${(props) => props.MinHeight};
  min-height: ${(props) => props.MinnHeight};
  border: 1px solid #dcdbdb;
  overflow: scroll;

  @media (min-width: 720px) {
    width: 50%;
    padding: 1rem;
  }
  @media (min-width: 550px) {
    height: ${(props) => props.height780};
  }
  @media (max-width: 780px) {
    height: ${(props) => props.maxHeight780};
  }
  @media (min-width: 1020px) {
    /* Add styles specific to a minimum width of 720px */
    /* For example, you can modify the padding or font size */
    width: 45%;
    padding: 1rem;
  }
`;

export const StyledModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9000;
  transition: linear 0.3s;
  backdrop-filter: blur(3px);
  overflow: hidden;
`;
export const StyledModalLoader = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 9000;
  transition: linear 0.3s;
  
`;
export const StyledModalBackground1 = styled.div`
  width: 100vw;
  height: ${(props) => props.height || '100vh'};
  display: flex;
  align-items: end;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9000;
  backdrop-filter: blur(3px);
  transition: linear 0.3s;
  overflow: hidden;

  @media (min-width: 780px) {
    display: flex;
    align-items: end;
    justify-content: end;
  }
`;

export const Button = styled.button`
  background-color: ${(props) => props.backgroundcolor || "transparent"};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontsize1};
  display: flex;
  align-items: center;
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.radius};
  cursor: pointer;
  transition: ${(props) => props.trans || "linear .3s"};
  justify-content: ${(props) => props.align || "center"};
  position: ${(props) => props.position};
  box-shadow: ${(props) => props.shadow1};

  &:hover {
    text-decoration: ${(props) => props.TextDecoration};
    box-shadow: ${(props) => props.shadow};
    background: ${(props) => props.background};
  }
  &:focus-within {
    box-shadow: ${(props) => props.shad || `0px 0px 4px ${Colors.black}`};
    background: ${(props) => props.background1};
    color: ${(props) => props.color1};
  }

  div {
    display: flex;
    padding-left: ${(props) => props.paddingL};
    padding-right: ${(props) => props.paddingR};
    font-size: 1.5rem;
  }
`;

export const DateInput = styled.input`
  padding: ${(props) => props.padding};
  font-size: ${(props) => props.fontsize1};
  color: ${(props) => props.color};
  width: ${(props) => props.width};
  border-radius: 0.4rem;
  border: 1px solid #aaa;
  outline: none;

  &::placeholder {
    color: #b9b6c6;
    letter-spacing: 0.5px;
    font-size: 0.88rem;
  }
  &:active {
    outline: 1px solid ${Colors.PrimaryColor};
  }
`;
