export const Colors = {
  PrimaryColor: "#ff6700",
  lightPri: "#fff0e6",
  SecondaryColor: "#66b6ff",
  BodyBgColor: "#f8f8f8",
  BoxShadow: "#ececec",
  border: "#d5dee5",
  font1: '#868fa0',
  font3: '#747688',
  font2: '#969494',
  white: '#fff',
  black: '#212121',
};
