import React, { useEffect, useState } from "react";

import styled from "styled-components";
import Aos from "aos";
import {
  StyledModalBackground1,
  StyledModalContent,
} from "../Reuseable/StyledComponents";
import { Colors } from "../Style/Colours";
import avata from "../assests/live/vector.svg";
import axios from "axios";
import { secret } from "../Request/Secret";
import { HiUserGroup } from "react-icons/hi";

function GuestList() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [people, setPeople] = useState([]);

  const getDetails = JSON.parse(sessionStorage.getItem("hostD"));

  const headers = {
    "Content-Type": "application/json",
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const requestData = {
        code: getDetails?.code,
        // secret,
      };

      const response = await axios.post(
        `https://backend.partytime.ng/guestapi/hostguestlist/`,
        requestData,
        headers
      );

      if (response?.data?.status) {
        setPeople(response?.data?.data?.["all users"]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    Aos.init({
      duration: 500,
      easing: "ease-in-out",
    });
  }, []);

  // Get the total window height
  const totalHeight = window.outerHeight;

  // Get the viewport height
  const viewportHeight = window.innerHeight;

  // Calculate the header height
  const headerHeight = totalHeight - viewportHeight;

  return (
    <Container>
      <div
        onClick={() => {
          setShowModal(true);
          fetchData();
        }}
        className="header"
      >
        {/* <img src={GuestIcon} alt="" /> */}
        <HiUserGroup size={28} />
      </div>

      {showModal && (
        <StyledModalBackground1
          height={`cal(100vh - ${headerHeight}px)`}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setShowModal(false);
            }
          }}
        >
          <StyledModalContent
            padding="1rem 0 0 0"
            radius="1rem 1rem 0 0"
            MinHeight="85vh"
            width="100%"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="400"
          >
            <div className="header">
              <div
                style={{
                  width: "20%",
                  color: Colors.font3,
                  fontSize: "0.8rem",
                }}
              ></div>
              <div style={{ width: "60%", margin: "auto" }}>All Guests</div>
              <div
                style={{ width: "20%" }}
                className="Close"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Close
              </div>
            </div>

            <div
              style={{
                padding: ".9rem .6rem 2rem .6rem",
                background: "#f9f9f9",
              }}
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "20vh",
                  }}
                >
                  Loading...
                </div>
              ) : (
                <>
                  {people.length > 0 ? (
                    people.map((item, index) => (
                      <div key={index} className="leader">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ width: "50px", height: "50px" }}>
                            <img
                              src={
                                item.avatar === null || !item.avatar
                                  ? avata
                                  : item.avatar
                              }
                              alt=""
                              className="avata"
                              style={{ objectFit: "contain" }}
                              width="100%"
                              height="100%"
                            />
                          </div>
                          <div
                            style={{ fontWeight: "600", paddingLeft: "1rem" }}
                          >
                            <span style={{ color: Colors.font3 }}>
                              {item.username}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        height: "20vh",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <div style={{ fontWeight: "600" }}>
                          Absence of Guests
                        </div>
                        <p
                          style={{
                            fontSize: ".9rem",
                            padding: ".5rem 0",
                            color: "#666666",
                          }}
                        >
                          {/* You can add a message here if needed */}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </StyledModalContent>
        </StyledModalBackground1>
      )}
    </Container>
  );
}

export default GuestList;

const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    text-align: center;
    cursor: pointer;
    /* border-bottom: 1px solid #ddd; */
    padding: 0.7rem;
    border-radius: 50%;
    background-color: rgba(256, 256, 256, 0.5);
  }

  .Close {
    color: ${Colors.PrimaryColor};
    font-size: 0.9rem;
    cursor: pointer;
  }

  .avata {
    border-radius: 50%;
  }

  .leader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.5rem;
    border-bottom: 1px solid rgba(235, 233, 233, 0.3);
    background: #fff;
    padding: 0.1rem 1rem 0.1rem 0.1rem;
    margin: 0.5rem 0;
    transition: linear 0.3s;
    box-shadow: 0px 0px 10px ${Colors.lightPri};
  }

  .request {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    color: #343434;
    padding: 0.5rem;
    border-radius: 0 0 1.5rem 1.5rem;
  }
`;
