import LiveVideo from './LiveVideo';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/:code" element={<LiveVideo />} />
	<Route path="/host" element={<h1>host route</h1>} />
        <Route path="/" element={<LiveVideo />} />
      </Route>
    )
  );
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
