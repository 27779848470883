import React, { useState } from "react";
import styled from "styled-components";

import { Colors } from "../Colours";

function ReEnterURL({setShowModal}) {
  const [loading, setloading] = useState(false);
  const [EventCode, setEventCode] = useState('');

  const handleSubmit = () => {
    if (EventCode) {
      setloading(true)
      window.location.assign(EventCode) 
    }
  }
  return (
    <div>
      <StyledModalBackground1 
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setShowModal(false);
        }
      }}>
        <StyledModalContent>
          <div>
            <div className="error">Opps! <strong>Event Code</strong> missing</div>
            <label>Re-enter URL / Event Code to continue</label>
            <input
              type="text"
              required
              placeholder="Re-enter / Re-paste the URL you used Initially"
              onChange={(e) => setEventCode(e?.target?.value)}
            />
          </div>

          <Button
            color="white"
            backgroundcolor={Colors.PrimaryColor}
            padding=".8rem"
            width="100%"
            type="submit"
            fontsize1="1rem"
            radius="2rem"
            shadow={`3px 0px 5px${Colors.BoxShadow}`}
            onClick={handleSubmit}
            style={{ marginTop: "1rem", fontWeight: "500" }}
          >
            {loading ? "loading..." : "Continue"}
          </Button>
        </StyledModalContent>
      </StyledModalBackground1>
    </div>
  );
}

export default ReEnterURL;

const StyledModalContent = styled.div`
  padding: 1rem;
  border-radius: 0.4rem;
  width: 50%;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8);
  max-height: ${(props) => props.MinHeight};
  min-height: ${(props) => props.MinnHeight};
  border: 1px solid #dcdbdb;
  overflow: scroll;
  margin-top: 3rem;

  input {
    width: 90%;
    padding: 1rem;
    margin: 0.5rem 0 1.2rem 0;
    border: 1px solid #ccc;
    border-radius: 0.4rem;
  }

  label {
    font-size: 0.9rem;
    color: #212121;
    font-family: MediumFont;
  }

  .error{
    text-align: center;
    padding: 1rem 0;
    margin-bottom: 1rem;
    font-style: italic;
  }
`;

const StyledModalBackground1 = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: start;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9000;
  transition: linear 0.3s;
`;

const Button = styled.button`
  background-color: ${(props) => props.backgroundcolor || "transparent"};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontsize1};
  display: flex;
  align-items: center;
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.radius};
  cursor: pointer;
  transition: ${(props) => props.trans || "linear .3s"};
  justify-content: ${(props) => props.align || "center"};
  position: ${(props) => props.position};
  box-shadow: ${(props) => props.shadow1};

  &:hover {
    text-decoration: ${(props) => props.TextDecoration};
    box-shadow: ${(props) => props.shadow};
    background: ${(props) => props.background};
  }
  &:focus-within {
    box-shadow: ${(props) => props.shad || `0px 0px 4px ${Colors.black}`};
    background: ${(props) => props.background1};
    color: ${(props) => props.color1};
  }

  div {
    display: flex;
    padding-left: ${(props) => props.paddingL};
    padding-right: ${(props) => props.paddingR};
    font-size: 1.5rem;
  }
`;
