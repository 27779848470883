import React, { useEffect, useState } from "react";
import {
  Button,
  StyledModalBackground,
  StyledModalContent,
} from "../Reuseable/StyledComponents";
import { Colors } from "../Style/Colours";
import styled from "styled-components";
import AOS from "aos";
import { secret } from "../Request/Secret";
import { toast } from "sonner";
import axios from "axios";
import { LuArrowRightFromLine } from "react-icons/lu";
import ReEnterURL from "./ReEnterURL";
import ThankYouMessage from "./ThankYouMessage";
// import { useNavigate } from "react-router-dom";

function ThankYouModal() {
  const [isopen, setIsopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [seeMessage, setSeeMessage] = useState(false);
  const [message, setMessage] = useState("");

  // const navigate = useNavigate();

  const getDetails = JSON.parse(sessionStorage.getItem("hostD"));

  const headers = {
    "Content-Type": "application/json",
  };

  const fetchUserToLeave = async () => {
    setLoading(true);
    try {
      const requestData = {
        code: getDetails?.code,
        secret,
        remove: "yes",
      };

      const response = await axios.post(
        `https://backend.partytime.ng/guestapi/alluserevent/`,
        requestData,
        headers
      );

      if (response?.data?.status) {
        //   setPeople(response?.data?.data?.["all users"]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchMessage = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(
        `https://backend.partytime.ng/guestapi/hostmessage?code=${getDetails?.code}`,
        headers
      );

      if (response?.data?.status) {
        setMessage(response?.data?.message);
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
    }
  };

  const handleLeave = () => {
    // handleSpray();
    fetchUserToLeave();
    setSeeMessage(true);

    toast.loading("", {
      description: "Leaving...",
      style: { color: "green" },
    });

    // sessionStorage.removeItem("hostD");
    // navigate('/')
    // window.location.assign('/')

    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      window.close();
    }, 5000);
  };

  // const handleFundWallet = () => {
  // sessionStorage.removeItem("balance");
  // sessionStorage.removeItem("curr");
  // sessionStorage.removeItem("curr2");
  // localStorage.removeItem("a1mount2");
  // navigate(`/amount`);
  // };

  useEffect(() => {
    fetchMessage();
    AOS.init({
      duration: 300,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <Container>
      <div
        className="cancel"
        onClick={() => {
          setIsopen(true);
          fetchMessage();
        }}
      >
        <LuArrowRightFromLine size={25} />
      </div>
      {isopen && (
        <StyledModalBackground
          onClick={(e) => {
            // Check if the clicked element is the background itself
            if (e.target === e.currentTarget) {
              setIsopen(false);
            }
          }}
        >
          {seeMessage ? (
            <><ThankYouMessage message={message} /></>
          ) : (
            <StyledModalContent
              width="80%"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="400"
            >
              <div className="thankyouall">
                {/* <div style={{width: '70px', margin: 'auto'}}>
            <img src={exceed} width="100%" />
          </div> */}
                <h3 style={{ paddingBottom: ".6rem", fontSize: "1.4rem" }}>
                  Close Host Interface?
                </h3>

                {getDetails?.status === "Live" ? (
                  <>
                    {" "}
                    <p style={{ color: Colors?.font1, fontSize: ".9rem" }}>
                      Party is still live and on going, Are you sure you want to
                      leave?
                    </p>
                    <Button
                      color="white"
                      backgroundcolor={Colors.PrimaryColor}
                      padding=".6rem"
                      width="100%"
                      type="submit"
                      fontsize1=".9rem"
                      radius="2rem"
                      shadow={`3px 0px 5px${Colors.BoxShadow}`}
                      onClick={handleLeave}
                      style={{ margin: "3rem 0 .5rem 0", fontWeight: "600" }}
                    >
                      Yes, I'm done
                    </Button>
                    <Button
                      color="black"
                      padding=".6rem"
                      width="100%"
                      type="submit"
                      fontsize1=".9rem"
                      radius="2rem"
                      border={`1px solid ${Colors?.font1}`}
                      shadow={`3px 0px 5px${Colors.BoxShadow}`}
                      onClick={() => {
                        setIsopen(false);
                      }}
                      style={{ fontWeight: "600" }}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    {" "}
                    <p style={{ color: Colors?.font1, fontSize: ".9rem" }}>
                      You are you sure you want to leave?
                    </p>
                    <Button
                      color="white"
                      backgroundcolor={Colors.PrimaryColor}
                      padding=".6rem"
                      width="100%"
                      type="submit"
                      fontsize1=".9rem"
                      radius="2rem"
                      shadow={`3px 0px 5px${Colors.BoxShadow}`}
                      onClick={handleLeave}
                      style={{ margin: "3rem 0 .5rem 0", fontWeight: "600" }}
                    >
                      Yes, I'm done
                    </Button>
                    <Button
                      color="black"
                      padding=".6rem"
                      width="100%"
                      type="submit"
                      fontsize1=".9rem"
                      radius="2rem"
                      border={`1px solid ${Colors?.font1}`}
                      shadow={`3px 0px 5px${Colors.BoxShadow}`}
                      onClick={() => {
                        setIsopen(false);
                      }}
                      style={{ fontWeight: "600" }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </div>
            </StyledModalContent>
          )}
        </StyledModalBackground>
      )}
      {showModal && <ReEnterURL setShowModal={setShowModal} />}
    </Container>
  );
}

export default ThankYouModal;

const Container = styled.div`
  .cancel {
    /* margin-bottom: 0.4rem; */
    display: flex;
    border-radius: 50%;
    color: white;
    background: rgba(256, 256, 256, 0.5);
    padding: 0.8rem;
    cursor: pointer;
    opacity: 1;
    transition: box-shadow 0.3s ease; /* Add transition for box-shadow */
    box-shadow: 0px 0px 10px #414141; /* Initial box-shadow */

    &:hover {
      box-shadow: 0px 0px 20px #414141;
    }
  }

  .thankyouall {
    padding: 1rem;
    text-align: center;
  }
`;
